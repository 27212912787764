import { Box, Typography } from "@outschool/backpack";
import { centsToDollars } from "@outschool/business-rules";
import {
  ActivityEnrollQueryQuery,
  EnrollmentMeetingPurchaseQueryQuery
} from "@outschool/gql-frontend-generated";
import * as Localization from "@outschool/localization";
import { Trans, useTranslation } from "@outschool/localization";
import {
  nextMonthlyPaymentDeadline,
  nextRenewalDeadline,
  nextWeeklyPaymentDeadline
} from "@outschool/time";
import { ExternalLink } from "@outschool/ui-components-shared";
import { Checkbox } from "@outschool/ui-legacy-component-library";
import React from "react";

import * as Routes from "../../../shared/Routes";
import { useTimeZone } from "../../providers/TimeZoneProvider";
import NoCheckBoxDisclaimer from "./NoCheckBoxDisclaimer";

export enum BulkPurchaseRecurringPaymentTypes {
  Fixed = "fixed",
  Ongoing = "ongoing",
  Mixed = "mixed"
}

export const STRIPE_TOS_URL = "https://stripe.com/legal/consumer";
export const STRIPE_PRIVACY_POLICY_URL = "https://stripe.com/privacy";

type StripeAuthorizationProps = {
  setIsSaveForLater: (isSaved: boolean) => void;
  isSaveForLater: boolean;
  isPaymentProcessing: boolean;
  isPaymentSuccessful: boolean;
  activity?:
    | NonNullable<ActivityEnrollQueryQuery["enrollmentForm"]>["activity"]
    | NonNullable<
        EnrollmentMeetingPurchaseQueryQuery["enrollmentMeetingPurchaseForm"]
      >["activity"];
  totalCents?: number;
  isWeeklyPayment?: boolean;
  bulkPurchaseRecurringPaymentMethod?: BulkPurchaseRecurringPaymentTypes;
  isThreeStepBooking?: boolean;
  durationWeeks?: number | null;
};

export const bulkStopRecurringMethodText = (
  t: Localization.TFunction,
  bulkPurchasePaymentMethod: BulkPurchaseRecurringPaymentTypes
) => {
  switch (bulkPurchasePaymentMethod) {
    case BulkPurchaseRecurringPaymentTypes.Fixed:
      return t("“Withdraw” link");
    case BulkPurchaseRecurringPaymentTypes.Ongoing:
      return t("“Stop Subscription” link");
    case BulkPurchaseRecurringPaymentTypes.Mixed:
      return t("“Withdraw” or “Stop Subscription” link");
  }
};

export default function StripeAuthorization({
  setIsSaveForLater,
  isSaveForLater,
  isPaymentProcessing,
  isPaymentSuccessful,
  activity,
  totalCents,
  isWeeklyPayment,
  bulkPurchaseRecurringPaymentMethod,
  isThreeStepBooking,
  durationWeeks
}: StripeAuthorizationProps) {
  const { t } = useTranslation(
    "client\\components\\payments\\StripeAuthorization"
  );
  const locale = Localization.useLocale();
  const { timeZone } = useTimeZone();

  const paymentAmount = totalCents ? `$${centsToDollars(totalCents)} USD` : "";

  let disclaimerText;

  if (!!bulkPurchaseRecurringPaymentMethod) {
    const stopPaymentMethod = bulkStopRecurringMethodText(
      t,
      bulkPurchaseRecurringPaymentMethod
    );
    // For bulk checkout, don't include the payment amount or charge time info
    disclaimerText = (
      <Trans t={t}>
        <p>
          I acknowledge the charges will automatically be charged weekly on
          Sunday. To remove your learner from a class and stop recurring
          charges, please visit the classroom page for the class and click on
          the {{ stopPaymentMethod }}.{" "}
          <ExternalLink
            url={Routes.understandingOngoingClassesSupportUrl(locale)}
            trackingName="checkout_ongoing_class_support_link"
          >
            Learn more
          </ExternalLink>
        </p>
        <p>
          I authorize Outschool, Inc. to charge my credit card as payment for
          the classes on a recurring basis through its third-party vendor, Link
          by Stripe, Inc., processing such personal information in accordance
          with their{" "}
          <ExternalLink url={STRIPE_TOS_URL}>Terms of Services</ExternalLink>{" "}
          and{" "}
          <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
            Privacy Policy
          </ExternalLink>
          . Outschool does not store or process this information.
        </p>
      </Trans>
    );
  } else if (activity && !!activity.is_ongoing_weekly) {
    // 5PM UTC converted to the user’s timezone
    const renewalDay = nextRenewalDeadline(timeZone);
    const dayOfWeek = renewalDay.format("dddd");
    const timeWithTimeZone = renewalDay.format("LT z");
    disclaimerText = (
      <Trans t={t}>
        <p>
          I acknowledge the charges will automatically be charged weekly on{" "}
          {{ dayOfWeek }} at {{ timeWithTimeZone }}. To cancel your
          subscription, please visit the classroom page for the class and click
          on the “Stop Subscription” link.{" "}
          <ExternalLink
            url={Routes.understandingOngoingClassesSupportUrl(locale)}
            trackingName="checkout_ongoing_class_support_link"
          >
            Learn more
          </ExternalLink>
        </p>{" "}
        <p>
          I authorize Outschool, Inc. to charge my credit card{" "}
          {{ paymentAmount }} as payment for the class on a recurring basis
          through its third-party vendor, Link by Stripe, Inc., processing such
          personal information in accordance with their{" "}
          <ExternalLink url={STRIPE_TOS_URL}>Terms of Services</ExternalLink>{" "}
          and{" "}
          <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
            Privacy Policy
          </ExternalLink>
          . Outschool does not store or process this information.
        </p>
      </Trans>
    );
  } else if (activity && activity.hasTeacherSchedule) {
    // 2AM UTC converted to the user’s timezone

    const weeklyPaymentDay = nextWeeklyPaymentDeadline(timeZone);
    const dayOfWeek = weeklyPaymentDay.format("dddd");
    const timeWithTimeZone = weeklyPaymentDay.format("LT z");
    if (isThreeStepBooking) {
      // If durationWeeks is 1, we render the disclaimer for a one-time purchase.
      if (durationWeeks === 1) {
        return <NoCheckBoxDisclaimer />;
      }

      disclaimerText = (
        <Trans t={t}>
          <p>
            I authorize Outschool, Inc. to charge my credit card{" "}
            {{ paymentAmount }} as payment for the class on a recurring basis
            through its third-party vendor, Link by Stripe, Inc., processing
            such personal information in accordance with their{" "}
            <ExternalLink url={STRIPE_TOS_URL}>Terms of Services</ExternalLink>{" "}
            and{" "}
            <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
              Privacy Policy
            </ExternalLink>
            . Outschool does not store or process this information.
          </p>
          <p>
            I acknowledge the charges will automatically be charged on{" "}
            {{ dayOfWeek }} at {{ timeWithTimeZone }} for upcoming sessions. To
            remove your learner from the class and stop recurring charges,
            please visit the classroom page for the class and click “Cancel”.{" "}
            <ExternalLink
              url={Routes.understandingOngoingClassesSupportUrl(locale)}
              trackingName="checkout_ongoing_class_support_link"
            >
              Learn more
            </ExternalLink>
          </p>
        </Trans>
      );
    } else {
      disclaimerText = (
        <Trans t={t}>
          <p>
            I acknowledge the charges will automatically be charged on{" "}
            {{ dayOfWeek }} at {{ timeWithTimeZone }}.{" "}
            <ExternalLink
              url={Routes.understandingOngoingClassesSupportUrl(locale)}
              trackingName="checkout_ongoing_class_support_link"
            >
              Learn more
            </ExternalLink>
          </p>
          <p>
            I authorize Outschool, Inc. to charge my credit card{" "}
            {{ paymentAmount }} as payment for the class on a recurring basis
            through its third-party vendor, Link by Stripe, Inc., processing
            such personal information in accordance with their{" "}
            <ExternalLink url={STRIPE_TOS_URL}>Terms of Services</ExternalLink>{" "}
            and{" "}
            <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
              Privacy Policy
            </ExternalLink>
            . Outschool does not store or process this information.
          </p>
        </Trans>
      );
    }
  } else if (activity && activity.isClub) {
    // 2AM UTC converted to the user’s timezone
    const monthlyPaymentDay = nextMonthlyPaymentDeadline(timeZone);
    const timeWithTimeZone = monthlyPaymentDay.format("LT z");
    disclaimerText = (
      <Trans t={t}>
        <p>
          I acknowledge the charges will automatically be charged at the end of
          each month at {{ timeWithTimeZone }}. To cancel your subscription,
          please visit the classroom page for the group and click on the “Stop
          Subscription” link.{" "}
          {/*  we currently show the ongoing class support article for groups because it's the closest explanation that we have */}
          <ExternalLink
            url={Routes.understandingOngoingClassesSupportUrl(locale)}
            trackingName="checkout_ongoing_class_support_link"
          >
            Learn more
          </ExternalLink>
        </p>
        <p>
          I authorize Outschool, Inc. to charge my credit card{" "}
          {{ paymentAmount }} as payment for the group on a recurring basis
          through its third-party vendor, Link by Stripe, Inc., processing such
          personal information in accordance with their{" "}
          <ExternalLink url={STRIPE_TOS_URL}>Terms of Services</ExternalLink>{" "}
          and{" "}
          <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
            Privacy Policy
          </ExternalLink>
          . Outschool does not store or process this information.
        </p>
      </Trans>
    );
  } else if (isWeeklyPayment) {
    // 2AM UTC converted to the user’s timezone
    const weeklyPaymentDay = nextWeeklyPaymentDeadline(timeZone);
    const dayOfWeek = weeklyPaymentDay.format("dddd");
    const timeWithTimeZone = weeklyPaymentDay.format("LT z");
    disclaimerText = (
      <Trans t={t}>
        <p>
          I acknowledge the charges will automatically be charged weekly on{" "}
          {{ dayOfWeek }} at {{ timeWithTimeZone }}. To remove your learner from
          the class and stop recurring charges, please visit the classroom page
          for the class and click on the “Withdraw” link.
        </p>
        <p>
          I authorize Outschool, Inc. to charge my credit card{" "}
          {{ paymentAmount }} as payment for the class on a recurring basis
          through its third-party vendor, Link by Stripe, Inc., processing such
          personal information in accordance with their{" "}
          <ExternalLink url={STRIPE_TOS_URL}>Terms of Services</ExternalLink>{" "}
          and{" "}
          <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
            Privacy Policy
          </ExternalLink>
          . Outschool does not store or process this information.
        </p>
      </Trans>
    );
  } else {
    return <NoCheckBoxDisclaimer />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        marginBottom: "1em",
        padding: 16,
        borderRadius: 8,
        width: "100%"
      }}
    >
      <Box
        flex
        sx={{
          fontSize: "0.875em"
        }}
      >
        <Checkbox
          id="disclaimer-checkbox"
          checked={isSaveForLater}
          disabled={isPaymentProcessing || isPaymentSuccessful}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setIsSaveForLater(e.target.checked)
          }
        />

        <label htmlFor="disclaimer-checkbox">
          <Typography
            variant="inherit"
            component="div"
            sx={{
              marginLeft: "0.5em",
              "p:not(:first-child)": {
                paddingTop: 8
              }
            }}
          >
            {disclaimerText}
          </Typography>
        </label>
      </Box>
    </Box>
  );
}
