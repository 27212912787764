import {
  SubscriptionCreditBalanceQuery,
  SubscriptionCreditBalanceQueryVariables
} from "@outschool/gql-frontend-generated";
import { dayjs } from "@outschool/time";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import React from "react";

const subscriptionCreditBalanceQuery = gql`
  query SubscriptionCreditBalance {
    subscriptionCreditBalance {
      ...SubscriptionCreditBalanceFragment
    }
  }

  fragment SubscriptionCreditBalanceFragment on SubscriptionCreditBalance {
    uid
    status
    subscriptionId
    checkoutId
    balance
    openToBuy
    packageName
    numCredits
    creditValue
    availableCredits
    billingCycleStart
    billingCycleEnd
    cancelAtPeriodEnd
    amountCents
    upcomingPackage {
      packageName
      numCredits
      creditValue
      amountCents
    }
  }
`;

export const useSubscriptionCreditBalance = () => {
  const { isLoggedIn } = useSession();

  const { data, loading, refetch } = useQueryWithPreviousData<
    SubscriptionCreditBalanceQuery,
    SubscriptionCreditBalanceQueryVariables
  >(subscriptionCreditBalanceQuery, {
    skip: !isLoggedIn
  });

  const {
    status,
    availableCredits,
    subscriptionCreditLimit,
    nextSubscriptionStartDate,
    cancelAtPeriodEnd,
    amountCents,
    upcomingPackageCredits,
    upcomingPackageCost
  } = React.useMemo(() => {
    const creditBalance = data?.subscriptionCreditBalance ?? null;
    const upcomingPackage = creditBalance?.upcomingPackage;
    const subscriptionCreditLimit = !!upcomingPackage?.numCredits
      ? upcomingPackage?.numCredits
      : !!creditBalance?.numCredits
      ? creditBalance?.numCredits
      : 0;

    return {
      status: creditBalance?.status,
      availableCredits: creditBalance?.availableCredits ?? 0,
      subscriptionCreditLimit,
      nextSubscriptionStartDate: !!creditBalance?.billingCycleEnd
        ? dayjs(creditBalance?.billingCycleEnd).add(1, "day").toDate()
        : null,
      cancelAtPeriodEnd: creditBalance?.cancelAtPeriodEnd,
      amountCents: creditBalance?.amountCents ?? 0,
      upcomingPackageCredits: upcomingPackage?.numCredits ?? null,
      upcomingPackageCost: upcomingPackage?.amountCents ?? null
    };
  }, [data]);

  return {
    status,
    availableCredits,
    subscriptionCreditLimit,
    rolloverSubscriptionCreditLimit: subscriptionCreditLimit * 2,
    nextSubscriptionStartDate,
    cancelAtPeriodEnd,
    amountCents,
    upcomingPackageCredits,
    upcomingPackageCost,
    loading,
    refetch
  };
};
