import { gql } from "@outschool/ui-apollo";

import LearnerFragment from "./LearnerFragment";
import { ParentFragment } from "./ParentFragments";
import { SectionFragment } from "./SectionFragments";

export default gql`
  fragment EnrollmentFragment on Enrollment {
    uid
    section_uid
    price_cents
    priceCents
    pricingOffer {
      name
      buyerOrgName
      isFinancialAid
    }
    created_at
    confirmed_at
    confirmedAt
    withdrawn_at
    ongoing_stopped_at
    currency_code
    exchange_rate
    amount_in_local_currency
    ongoing_start_time
    ongoing_end_time
    withdrawnAt
    ongoingStoppedAt
    ongoingStartTime
    ongoingEndTime
    isPaused
    isEnded
    isPrepay
    refundableAmountCents
    refundPolicy {
      type
      name
      description
    }
    canBeWithdrawn
    canBeStopped
    canBePaused
    usersCanCommunicate
    purchasedAsGift
    details {
      name
      age
      attendedAt
      message
    }
    parent {
      ...ParentFragment
    }
    buyer {
      uid
      ...ParentFragment
    }
    learner {
      ...LearnerFragment
    }
    siblings {
      learner {
        ...LearnerFragment
      }
      currentOrLastSection {
        filledSpaceCount
        ...SectionFragment
      }
    }
    paymentSchedule {
      uid
      stoppedAt
      endTime
      scheduleType
      amountCents
    }
    pauseWindow {
      uid
      startDate
      endDate
      canBeCanceled
      pauseReason
    }
  }
  ${LearnerFragment}
  ${ParentFragment}
  ${SectionFragment}
`;

export const EnrollmentLearnerFragment = gql`
  fragment EnrollmentLearnerFragment on Enrollment {
    uid
    section_uid
    price_cents
    priceCents
    pricingOffer {
      name
      buyerOrgName
      isFinancialAid
    }
    created_at
    confirmed_at
    confirmedAt
    withdrawn_at
    ongoing_stopped_at
    currency_code
    exchange_rate
    amount_in_local_currency
    ongoing_start_time
    ongoing_end_time
    withdrawnAt
    ongoingStoppedAt
    ongoingStartTime
    ongoingEndTime
    isPaused
    isEnded
    isPrepay
    refundableAmountCents
    refundPolicy {
      type
      name
      description
    }
    canBeWithdrawn
    canBeStopped
    canBePaused
    usersCanCommunicate
    purchasedAsGift
    details {
      name
      age
      attendedAt
      message
    }
    parent {
      ...ParentFragment
      hasSubscription
    }
    buyer {
      uid
      ...ParentFragment
    }
    learner {
      ...LearnerFragment
    }
    siblings {
      learner {
        ...LearnerFragment
      }
      currentOrLastSection {
        filledSpaceCount
        ...SectionFragment
      }
    }
    paymentSchedule {
      uid
      stoppedAt
      endTime
      scheduleType
      amountCents
    }
    pauseWindow {
      uid
      startDate
      endDate
      canBeCanceled
      pauseReason
    }
  }
  ${LearnerFragment}
  ${ParentFragment}
  ${SectionFragment}
`;
