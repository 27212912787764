import {
  Box,
  Image,
  List,
  ListItem,
  Modal,
  ModalContent,
  Theme,
  Typography,
} from "@outschool/backpack";
import { renderCurrencyString, useTranslation } from "@outschool/localization";
import { joinMembershipPath } from "@outschool/routes";
import { TrackedButton } from "@outschool/ui-components-shared";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";

import SubscriptionMeetImg from "../../images/subscription-meet.png";

export const COOKIE_SEEN_JOIN_SUBSCRIPTION = "os-join-subscription-seen";

export function JoinSubscriptionModal({
  isOpen,
  onClose,
  priceInCents,
  proceedToEnroll,
}: {
  isOpen: boolean;
  onClose: () => void;
  priceInCents?: number;
  proceedToEnroll?: (t?: any) => void;
}) {
  const { t } = useTranslation(
    "ui-components-website\\Modals\\JoinSubscriptionModal"
  );
  const navigate = useNavigation();
  const showDiscount = priceInCents && priceInCents > 0;
  const originalPriceWithCurrency =
    priceInCents && renderCurrencyString({ priceInCents });

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent
        sx={{
          maxWidth: "640px",
          padding: "0 0 0 2.4rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            flex={true}
            sx={{
              flexDirection: "column",
              paddingY: "2.4rem",
            }}
          >
            <Box>
              <Typography variant="display2">
                {t("Save up to 20% on every class!")}
              </Typography>
              <Typography variant="body1">
                {t(
                  "Learn with credits on Outschool—get them, earn them, use them!"
                )}
              </Typography>
            </Box>
            <Box>
              <List
                sx={{
                  padding: "2.3rem 0",
                  "& li": {
                    paddingX: "0rem",
                    paddingY: "0.3rem",
                    display: "block",
                  },
                }}
              >
                <ListItem>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    😘 {t("No Outschool fees—ever")}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      🤩 {t("Save up to 20% ")}&nbsp;
                    </Typography>{" "}
                    {t("on every enrollment")}
                  </Typography>
                </ListItem>
              </List>
              <TrackedButton
                variant="contained"
                trackingName="subscription_join_modal_cta"
                onClick={(_e: React.MouseEvent<HTMLButtonElement>) => {
                  navigate(joinMembershipPath());
                }}
              >
                {t("Show How It Works")}
              </TrackedButton>
              {showDiscount && (
                <Box
                  sx={{
                    paddingTop: "2.3rem",
                    paddingBottom: "0rem",
                  }}
                >
                  <TrackedButton
                    variant="link"
                    trackingName="subscription_join_modal_no_thanks"
                    onClick={proceedToEnroll}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                      {t("No thanks, enroll for {{enrollPrice}}", {
                        enrollPrice: originalPriceWithCurrency,
                      })}
                    </Typography>
                  </TrackedButton>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            flex={true}
            sx={(theme: Theme) => ({
              justifyContent: "space-between",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            })}
          >
            <Box>
              <Image src={SubscriptionMeetImg} />
            </Box>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}
