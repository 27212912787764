import { Box } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { ExternalLink } from "@outschool/ui-components-shared";
import React from "react";

import {
  STRIPE_PRIVACY_POLICY_URL,
  STRIPE_TOS_URL
} from "./StripeAuthorization";

export default function NoCheckBoxDisclaimer() {
  const { t } = useTranslation(
    "client\\components\\payments\\StripeAuthorization"
  );
  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        marginBottom: "1em",
        padding: 16,
        borderRadius: 8,
        width: "100%"
      }}
    >
      <Trans t={t}>
        <p>
          By providing your payment information, you acknowledge and consent to
          our third-party vendor, Link by Stripe, Inc., processing such personal
          information in accordance with their{" "}
          <ExternalLink url={STRIPE_TOS_URL}>Terms of Services</ExternalLink>{" "}
          and{" "}
          <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
            Privacy Policy
          </ExternalLink>
          . Outschool does not store or process this information.
        </p>
      </Trans>
    </Box>
  );
}
