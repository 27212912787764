import { useSession } from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";
import { useEffect } from "react";

export function useHideLearningPlannerExperiment() {
  const { currentUserHasLoaded } = useSession();

  const { variant, trigger } = useExperiment({
    currentUserHasLoaded,
    experimentName: "HideLearningPlanner",
  });

  useEffect(trigger, [trigger]);

  return {
    hideLearningPlanner: variant === "treatment",
  };
}
