import { OUTSCHOOL_TIMEZONE, dayjs } from "@outschool/time";

export enum FundingProgramAbbreviation {
  AZ = "AZ",
  AR = "AR",
  FL = "FL",
  EMA = "EMA",
  IN = "IN",
  ID = "ID",
  KS = "KS",
  MO = "MO",
  NH = "NH",
  NY = "NY",
  NC = "NC",
  OH = "OH",
  SC = "SC",
  TX = "TX",
  UT = "UT",
  VA = "VA",
}

export function isFundingProgramAbbreviation(
  input?: string | null
): input is FundingProgramAbbreviation {
  if (!input) {
    return false;
  }
  return [
    (input: string) => input,
    (input: string) => input.toLowerCase(),
    (input: string) => input.toUpperCase(),
  ].some(fn =>
    Object.values(FundingProgramAbbreviation).includes(fn(input) as any)
  );
}

type FundingProgramPropertiesType = {
  abbreviation: FundingProgramAbbreviation;
  isClassWallet: boolean;
  programName: string;
  programNameLong: string;
  programUrl?: string;
  programEndDate?: Date;
};

const FundingProgramProperties: Record<
  FundingProgramAbbreviation,
  FundingProgramPropertiesType
> = {
  [FundingProgramAbbreviation.AZ]: {
    abbreviation: FundingProgramAbbreviation.AZ,
    isClassWallet: true,
    programName: "AZ ESA",
    programNameLong: "Arizona ESA",
  },
  [FundingProgramAbbreviation.AR]: {
    abbreviation: FundingProgramAbbreviation.AR,
    isClassWallet: true,
    programName: "AR EFA",
    programNameLong: "Arkansas Education Freedom Account",
  },
  [FundingProgramAbbreviation.FL]: {
    abbreviation: FundingProgramAbbreviation.FL,
    isClassWallet: false,
    programName: "FL FES-UA/EO",
    programNameLong: "Florida FES-UA/EO",
    programUrl:
      "https://go.stepupforstudents.org/hubfs/GUIDES/FTC%20FES-EO%20Purchasing%20Guide%202023-24.pdf?_gl=1*12pgasb*_ga*NzczNjg5MTAwLjE3MTI2NzAyMTc.*_ga_V4KBYBT1HD*MTcxNTc0NTg1OS42LjEuMTcxNTc0NTg2MS41OC4wLjA.",
  },
  [FundingProgramAbbreviation.EMA]: {
    abbreviation: FundingProgramAbbreviation.EMA,
    isClassWallet: false,
    programName: "FL EMA",
    programNameLong: "Florida scholarship",
    programUrl:
      "https://support.outschool.com/en/articles/8496249-how-to-purchase-classes-using-your-florida-esa-funds",
  },
  [FundingProgramAbbreviation.ID]: {
    abbreviation: FundingProgramAbbreviation.ID,
    isClassWallet: true,
    programName: "ID EPP",
    programNameLong: "Idaho Empowering Parents Program",
  },
  [FundingProgramAbbreviation.IN]: {
    abbreviation: FundingProgramAbbreviation.IN,
    isClassWallet: true,
    programName: "IN ESA",
    programNameLong: "Indiana ESA",
  },
  [FundingProgramAbbreviation.KS]: {
    abbreviation: FundingProgramAbbreviation.KS,
    isClassWallet: false,
    programName: "KEEP",
    programNameLong: "Kansas Education Enrichment Program",
    programUrl: "https://www.keep.ks.gov/how-it-works",
  },
  [FundingProgramAbbreviation.NH]: {
    abbreviation: FundingProgramAbbreviation.NH,
    isClassWallet: true,
    programName: "NH EFA",
    programNameLong: "New Hampshire EFA",
  },
  [FundingProgramAbbreviation.NY]: {
    abbreviation: FundingProgramAbbreviation.NY,
    isClassWallet: true,
    programName: "CSF-NY",
    programNameLong: "New York CSF-NY",
  },
  [FundingProgramAbbreviation.NC]: {
    abbreviation: FundingProgramAbbreviation.NC,
    isClassWallet: true,
    programName: "NC ESA+",
    programNameLong: "North Carolina ESA+",
  },
  [FundingProgramAbbreviation.OH]: {
    abbreviation: FundingProgramAbbreviation.OH,
    isClassWallet: false,
    programName: "OH ACE",
    programNameLong: "Ohio ACE",
  },
  [FundingProgramAbbreviation.SC]: {
    abbreviation: FundingProgramAbbreviation.SC,
    isClassWallet: true,
    programName: "SC ESTF",
    programNameLong: "SC Education Scholarship Trust Fund",
    programUrl: "https://www.classwallet.com/programs/southcarolinaestf/",
  },
  [FundingProgramAbbreviation.TX]: {
    abbreviation: FundingProgramAbbreviation.TX,
    isClassWallet: true,
    programName: "TX SSES",
    programNameLong: "Texas SSES",
  },
  [FundingProgramAbbreviation.UT]: {
    abbreviation: FundingProgramAbbreviation.UT,
    isClassWallet: true,
    programName: "UT FAS",
    programNameLong: "Utah Fits All Scholarship Program",
    programUrl:
      "https://ufascholarship.com/wp-content/uploads/2024/02/UFA_FamilyHandbook-4-1.pdf",
  },
  [FundingProgramAbbreviation.MO]: {
    abbreviation: FundingProgramAbbreviation.MO,
    isClassWallet: true,
    programName: "MOScholars ESA",
    programNameLong: "MOScholars ESA",
  },
  [FundingProgramAbbreviation.VA]: {
    abbreviation: FundingProgramAbbreviation.VA,
    isClassWallet: true,
    programName: "VA LAG",
    programNameLong: "Virginia LAG",
    programUrl:
      "https://www.doe.virginia.gov/parents-students/for-parents/k-12-learning-acceleration-grants",
    programEndDate: dayjs("2024-05-31").toDate(),
  },
};

export const ClassWalletFundingProgramAbbreviations = Object.values(
  FundingProgramAbbreviation
).filter(abbr => FundingProgramProperties[abbr].isClassWallet);

export function getFundingProgramProperties(
  inputFundingProgramAbbr?: string | null
) {
  if (!inputFundingProgramAbbr) {
    return null;
  }

  const abbr = Object.values(FundingProgramAbbreviation).find(
    abbr => abbr === inputFundingProgramAbbr.toUpperCase()
  );

  if (!abbr) {
    return null;
  }

  return FundingProgramProperties[abbr];
}

export function getFundingProgramWaitPeriod(
  fundingProgram: string | undefined
): number {
  switch (fundingProgram) {
    case FundingProgramAbbreviation.TX:
      return 3;
    case FundingProgramAbbreviation.ID:
      return 5;
    case FundingProgramAbbreviation.AR:
      return 5;
    case FundingProgramAbbreviation.NC:
      return 5;
    case FundingProgramAbbreviation.NH:
      return 2;
    case FundingProgramAbbreviation.VA:
      return 2;
    case FundingProgramAbbreviation.NY:
      return 5;
    case FundingProgramAbbreviation.MO:
      return 5;
    case FundingProgramAbbreviation.SC:
      return 7;
    case FundingProgramAbbreviation.KS:
      return 42;
    default:
      // Currently AZ, UT
      return 0;
  }
}

export function getFundingProgramEndDate(
  fundingProgram: string | undefined,
  ignoreFirstDeadline = false
): Date | null {
  switch (fundingProgram) {
    case FundingProgramAbbreviation.VA:
      const deadlines = [
        new Date("2023-12-30T23:59:00"),
        new Date("2024-05-30T23:59:00"),
      ];
      return getNextValidFundingProgramDeadline(deadlines, ignoreFirstDeadline);
    default:
      return null;
  }
}

function getNextValidFundingProgramDeadline(
  deadlines: Date[],
  ignoreFirstDeadline: boolean
): Date | null {
  if (!deadlines || deadlines.length < 1) {
    return null;
  }

  const today = dayjs();
  let firstDeadlineIgnored = false;

  for (const date of deadlines) {
    if (
      dayjs
        .tz(today, OUTSCHOOL_TIMEZONE)
        .isAfter(dayjs.tz(dayjs(date), OUTSCHOOL_TIMEZONE))
    ) {
      continue;
    } else {
      if (ignoreFirstDeadline && !firstDeadlineIgnored) {
        firstDeadlineIgnored = true;
        continue;
      } else {
        return date;
      }
    }
  }
  return null;
}
