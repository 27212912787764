import { Box, Icon, Theme, Typography, styled } from "@outschool/backpack";
import { faCalendarRange, faHeart, faSearch, fasHome } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import {
  learnerPlanPath,
  parentProfilePath,
  savedClassesPath,
  searchPath
} from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { UserHeadshotImage } from "@outschool/ui-components-shared";
import { useHideLearningPlannerExperiment } from "@outschool/ui-components-website";
import { useImpressionTracking } from "@outschool/ui-legacy-component-library";
import { useIsMobile } from "@outschool/ui-utils";
import React, { forwardRef, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

export function useShouldShowMobileFooterTabBar() {
  const { currentUser } = useSession();
  const isMobile = useIsMobile();
  const location = useLocation();

  return useMemo(
    () =>
      !!currentUser &&
      !location.pathname.includes("/classes") &&
      !location.pathname.includes("/teachers") &&
      !location.pathname.includes("/groups") &&
      !location.pathname.includes("/learn/planner") &&
      !location.pathname.includes("/onboarding-quiz") &&
      isMobile,
    [currentUser, location.pathname, isMobile]
  );
}

const StyledLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.grey[500],
  paddingBlock: 8,
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "&:hover:not(:disabled)": {
    color: theme.palette.grey[500],
    textDecoration: "none"
  },
  ["&[aria-current='page']"]: {
    color: theme.palette.primary.main,
    fontWeight: "500"
  }
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  fontSize: "1.5em",
  color: theme.palette.grey[300],
  ["[aria-current='page'] &"]: { color: theme.palette.primary.main }
}));

function MobileFooterTab({
  icon,
  children,
  to,
  trackingName,
  onClick,
  ...props
}: {
  icon: React.ReactNode;
  children: React.ReactNode;
  trackingName: string;
  onClick?: () => void;
} & React.ComponentProps<typeof NavLink>) {
  const { trackTouch } = useImpressionTracking({
    trackingLabel: trackingName,
    uniqueId: `${trackingName}_mobile_footer_nav`,
    trackingEventName: `${trackingName}_mobile_footer_nav_event`
  });

  return (
    <StyledLink
      to={to}
      {...props}
      onClick={() => {
        trackTouch();
        if (onClick) {
          onClick();
        }
      }}
    >
      <Box
        sx={{
          minHeight: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        {icon}
      </Box>
      <Typography
        variant="body2"
        sx={{
          fontSize: "1rem",
          lineHeight: "1.8rem",
          fontWeight: "inherit"
        }}
      >
        {children}
      </Typography>
    </StyledLink>
  );
}

export const MobileFooterTabBar = forwardRef((_, ref) => {
  const shouldShow = useShouldShowMobileFooterTabBar();
  const { t } = useTranslation("client\\components\\nav\\MobileFooterTabBar");
  const { currentUser } = useSession();
  const { hideLearningPlanner } = useHideLearningPlannerExperiment();

  if (!shouldShow) {
    return null;
  }

  const isOnOwnProfile = currentUser
    ? // eslint-disable-next-line no-restricted-globals
      location.pathname.includes("/parents/" + currentUser.uid)
    : false;

  return (
    <Box
      ref={ref}
      as="nav"
      flex
      sx={(theme: Theme) => ({
        position: "fixed",
        zIndex: 10,
        bottom: -1,
        left: 0,
        backgroundColor: theme.palette.common.white,
        width: "100%",
        boxShadow: "0 5px 20px 0 #00000040"
      })}
    >
      <MobileFooterTab
        to={searchPath()}
        trackingName={"search"}
        icon={<StyledIcon icon={faSearch} />}
      >
        {t("Search")}
      </MobileFooterTab>

      {!hideLearningPlanner && (
        <MobileFooterTab
          to={learnerPlanPath()}
          trackingName={"planner"}
          icon={<StyledIcon icon={faCalendarRange} />}
        >
          {t("Planner")}
        </MobileFooterTab>
      )}

      <MobileFooterTab
        to="/"
        icon={<StyledIcon icon={fasHome} />}
        trackingName={"home"}
        isActive={(_, location) => {
          return (
            !location.pathname.includes("/search") &&
            !location.pathname.includes("/learn/planner") &&
            !location.pathname.includes("/account/saved-classes") &&
            !isOnOwnProfile &&
            !location.pathname.includes("/account/parent")
          );
        }}
      >
        {t("Home")}
      </MobileFooterTab>

      <MobileFooterTab
        to={savedClassesPath()}
        icon={<StyledIcon icon={faHeart} />}
        trackingName={"favorites"}
      >
        {t("Favorites")}
      </MobileFooterTab>

      {currentUser && (
        <MobileFooterTab
          isActive={(_, location) => {
            return (
              isOnOwnProfile || location.pathname.includes("/account/parent")
            );
          }}
          to={parentProfilePath(currentUser.uid)}
          icon={
            <Box sx={{ position: "relative" }}>
              <UserHeadshotImage size={30} user={currentUser} />
              {isOnOwnProfile && (
                <Box
                  sx={(theme: $TSFixMe) => ({
                    position: "absolute",
                    width: 30,
                    height: 30,
                    border: `2px solid ${theme.palette.primary.main}`,
                    borderRadius: "50%",
                    top: 0,
                    left: 0
                  })}
                />
              )}
            </Box>
          }
          trackingName={"profile"}
        >
          {t("Profile")}
        </MobileFooterTab>
      )}
    </Box>
  );
});
