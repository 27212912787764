import {
  Box,
  Button,
  Icon,
  Menu,
  Typography,
  useTheme
} from "@outschool/backpack";
import {
  faArrowRight,
  faExclamationTriangle,
  faInfoCircle
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { transactionsPath } from "@outschool/routes";
import { dayjs } from "@outschool/time";
import { CreditIcon, TrackedButton } from "@outschool/ui-components-shared";
import { CoinIcon } from "@outschool/ui-components-website";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import { useSubscriptionCreditBalance } from "../../hooks/useSubscriptionCreditBalance";
import { useTimeZone } from "../../providers/TimeZoneProvider";

export function SubscriptionCreditNavItem() {
  const { t } = useTranslation(
    "client\\components\\nav\\SubscriptionCreditNavItem"
  );
  const theme = useTheme();
  const {
    status,
    availableCredits,
    subscriptionCreditLimit,
    nextSubscriptionStartDate,
    loading: loadingSubscriptionCredits,
    cancelAtPeriodEnd
  } = useSubscriptionCreditBalance();
  const { timeZone } = useTimeZone();
  const [showModal, setShowModal] = React.useState<null | HTMLElement>(null);
  const [isCreditsModalOpen, setIsCreditsModalOpen] = React.useState(false);
  const [AddCreditsModal, setAddCreditsModal] =
    React.useState<React.ComponentType<any> | null>(null);

  if (loadingSubscriptionCredits || !status) {
    return null;
  }

  const navItemTextColor = availableCredits
    ? theme.palette.common.black
    : theme.palette.error[600];

  const handleOpenCreditsModal = async () => {
    // Dynamically import the modal when the button is clicked
    const module = await import(
      /* webpackChunkName: "subscriptions" */ "../subscriptions/AddCreditsModal"
    );
    setAddCreditsModal(() => module.default);
    setIsCreditsModalOpen(true);
    setShowModal(null);
  };

  const getCreditsCTA = () => {
    if (
      cancelAtPeriodEnd &&
      dayjs().isAfter(dayjs(nextSubscriptionStartDate).subtract(1, "week"))
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <Icon icon={faInfoCircle} sx={{ marginRight: 4, marginTop: 2 }} />
          <Typography variant="body2">
            {t`Once your membership ends, you will no longer have access to unused credits.`}
          </Typography>
        </Box>
      );
    }
    if (availableCredits > subscriptionCreditLimit) {
      return (
        <Box sx={{ display: "flex" }}>
          <Icon
            icon={faExclamationTriangle}
            sx={{
              marginRight: 4,
              marginTop: 2,
              color: "error.600"
            }}
          />
          <Typography variant="body2" sx={{ color: "error.600" }}>
            {t`Your rollover limit is`}
            <b>
              &nbsp;
              {t("{{subscriptionCreditLimit}} credits.", {
                subscriptionCreditLimit
              })}
              &nbsp;
            </b>
            {t`Please use any excess credits before your renewal date.`}
          </Typography>
        </Box>
      );
    }
    return (
      <>
        <Typography variant="body2">
          {availableCredits < 20
            ? t`You are running low on credits.`
            : t`Need more credits?`}{" "}
        </Typography>
        <Button
          variant="link"
          sx={{ color: theme.palette.primary.main, fontWeight: "normal" }}
          onClick={handleOpenCreditsModal}
        >
          &nbsp;
          {t`Buy more credits`}&nbsp;
        </Button>
        <Typography variant="body2"> {t`to continue learning!`}</Typography>
      </>
    );
  };

  return (
    <>
      <TrackedButton
        variant="link"
        trackingName="subscription_credit_nav_item"
        data-test-id="subscription-credit-nav-item"
        sx={{
          border: "none",
          paddingX: 8,
          paddingTop: 6,
          display: "block",
          color: navItemTextColor
        }}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          setShowModal(e.currentTarget);
        }}
      >
        <Box flex={true}>
          <Box sx={{ paddingTop: "0.2rem", paddingRight: "0.5rem" }}>
            <CreditIcon
              fillColor={
                availableCredits
                  ? theme.palette.warning.main
                  : theme.palette.error[200]
              }
              strokeColor={
                availableCredits
                  ? theme.palette.warning[700]
                  : theme.palette.error[600]
              }
            />
          </Box>
          <Typography
            variant="h5"
            sx={{
              color: navItemTextColor
            }}
          >
            {`${availableCredits}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">{t("Credits")}</Typography>
        </Box>
      </TrackedButton>
      <Menu
        anchorEl={showModal}
        open={Boolean(showModal)}
        onClose={(e: MouseEvent) => {
          e.preventDefault();
          setShowModal(null);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: 100 }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 16,
              paddingX: 8,
              maxWidth: "420px",
              boxShadow: "0px 0px 4em rgba(0, 0, 0, 0.2)"
            }
          }
        }}
      >
        <Box
          sx={{
            background:
              "linear-gradient(101deg, #4B01D4 -29.61%, #380596 58.3%)",
            boxShadow:
              "0px 0px 4px 0px rgba(92, 76, 236, 0.66), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "16px",
            padding: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {cancelAtPeriodEnd ? (
              <Typography variant="caption" sx={{ color: "white" }}>
                {t`Membership ending`}
              </Typography>
            ) : (
              <Typography variant="caption" sx={{ color: "white" }}>
                {t`Membership renewal`} {`- ${subscriptionCreditLimit} credits`}
              </Typography>
            )}
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", display: "block", color: "white" }}
            >
              {nextSubscriptionStartDate &&
                `${dayjs(nextSubscriptionStartDate)
                  .tz(timeZone)
                  .format("MMM D, YYYY")}`}
            </Typography>
            <Box sx={{ marginTop: 16 }}>
              <ManageSubscription />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end"
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 8,
                padding: "0.8rem 1.6rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CoinIcon
                fillColor={
                  availableCredits < 20
                    ? theme.palette.error.light
                    : theme.palette.warning.main
                }
                strokeColor={
                  availableCredits < 20
                    ? theme.palette.error.dark
                    : theme.palette.warning[700]
                }
              />{" "}
              <Typography variant="h1" sx={{ marginLeft: 4 }}>
                {availableCredits}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              sx={{ color: "white", marginTop: 4 }}
            >{t`Available credits`}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingX: 16,
            paddingTop: 16,
            paddingBottom: 8,
            display: "flex",
            flexWrap: "wrap"
          }}
        >
          {getCreditsCTA()}
        </Box>
      </Menu>
      {AddCreditsModal && (
        <AddCreditsModal
          isOpen={isCreditsModalOpen}
          onClose={() => setIsCreditsModalOpen(false)}
        />
      )}
    </>
  );
}

function ManageSubscription() {
  const { t } = useTranslation(
    "client\\components\\nav\\SubscriptionCreditNavItem"
  );
  const Link = useLinkComponent();

  return (
    <Link to={transactionsPath()}>
      <Typography
        sx={{ fontWeight: "fontWeightMedium", color: "white", fontSize: 14 }}
      >
        {t`Manage Plan`}
      </Typography>
      &nbsp;
      <Icon
        icon={faArrowRight}
        sx={{ marginRight: 4, color: "white", fontSize: 14 }}
      />
    </Link>
  );
}
