import pkg from "../lib/pkg";

import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";
import type { Context } from "../types";
import { getSessionId } from "@outschool/attribution";
import Cookies from "js-cookie";
import { CookieKeys } from "@outschool/data-schemas";
import { injectScript, logDebugEvent } from "../lib/util";

declare global {
  interface Window {
    sessionReplay?: {
      init: (_: string, obj: any) => void;
      getSessionReplayProperties: () => any;
      setSessionId: (_: number) => void;
      shutdown: () => void;
    };
  }
}
/*
 * This plugin is for session Replay function of Amplitude
 * is aim to use when Amplitude is tracked
 */

export const AMPLITUDE_SESSION_REPLAY_ACTIVE_PATH = [
  "/learn/planner",
  "/learn/planner/cart",
  "/search",
];
export default class AmplitudeSessionReplayPlugin implements AnalyticsPlugin {
  name = Integration.AmplitudeSessionReplay;
  category = IntegrationCategory.Functional;
  type = AnalyticsPluginType.enrichment;
  version = "0.1.0";
  sessionId = getSessionId() || 0;
  scriptId = "amplitudeSessionReplay";
  scriptSrc =
    "https://cdn.amplitude.com/libs/session-replay-browser-1.10.0-min.js.gz";

  isLoadable() {
    // const path = window?.location?.pathname;
    // return (
    //   pkg.isProduction &&
    //   !!path &&
    //   AMPLITUDE_SESSION_REPLAY_ACTIVE_PATH.includes(path)
    // );

    return false;
  }

  async load() {
    if (!pkg.amplitudeKey || typeof window === "undefined") {
      return;
    }

    await injectScript(this.scriptId, this.scriptSrc);

    if (!window?.sessionReplay) {
      return;
    }

    window?.sessionReplay.init(pkg.amplitudeKey, {
      // the amplitude's device id is mapped to segment's anonymousId
      deviceId: Cookies.get(CookieKeys.AnonymousId),
      sessionId: this.sessionId,
      sampleRate: 0.02,
    });
  }
  async unload() {
    if (!this.isLoaded()) {
      return;
    }
    window.sessionReplay?.shutdown();
    const script = document.getElementById(this.scriptId);
    script?.remove();
    window.sessionReplay = undefined;
  }

  isLoaded() {
    if (typeof window === "undefined") {
      return false;
    }

    return !!window?.sessionReplay;
  }

  track(context: Context): Context {
    const currentPath = window?.location?.pathname;
    if (
      !!window?.sessionReplay &&
      !!currentPath &&
      AMPLITUDE_SESSION_REPLAY_ACTIVE_PATH.includes(currentPath)
    ) {
      const { properties } = context.event;
      const sessionReplayProperties =
        window.sessionReplay.getSessionReplayProperties();

      context.event = {
        ...context.event,
        properties: { ...properties, ...sessionReplayProperties },
      };

      logDebugEvent(context.event);
    }
    return context;
  }
}
