import {
  Box,
  Icon,
  Image,
  Theme,
  Typography,
  visuallyHidden,
} from "@outschool/backpack";
import {
  faChevronDown,
  faChevronUp,
  faFacebook,
  faInstagram,
  faPinterest,
  faTwitter,
  faYoutube,
} from "@outschool/icons";
import {
  I18nLocale,
  getLanguageFromLocale,
  useLocale,
  useTranslation,
} from "@outschool/localization";
import {
  aboutPath,
  articlesPath,
  careersPath,
  categorySitemapUrl,
  downloadOnAppStoreFooterPath,
  downloadOnGooglePlayFooterPath,
  esaFundsFooterPath,
  facebookPageUrl,
  forSellerOrgPath,
  giftCardLandingPath,
  homePath,
  instagramPageUrl,
  newClassesOnOutschoolUrl,
  onlineClassesPath,
  pagesPath,
  pathToUrl,
  personalizedAdSettingsPath,
  pinterestPageUrl,
  pressUrl,
  privacyPath,
  privacyPathCA,
  privacyPathCOPPA,
  safetyPath,
  teachPath,
  teachTipsBaseUrl,
  teachTipsEducatorHandbookUrl,
  teachTipsEducatorInsightsUrl,
  teachTipsGettingStartedUrl,
  teachTipsStartTutoringUrl,
  termsPath,
  twitterPageUrl,
  youtubePageUrl,
} from "@outschool/routes";
import { dayjs } from "@outschool/time";
import { useAdSettings } from "@outschool/ui-analytics";
import { ExternalLink } from "@outschool/ui-components-shared";
import {
  ConditionalTrackingPageSection,
  Container,
  CurvedTopper,
  Space,
} from "@outschool/ui-legacy-component-library";
import {
  responsive,
  useIsMobile,
  useIsMounted,
  useLinkComponent,
} from "@outschool/ui-utils";
import React, { useMemo } from "react";

import DownloadFromAppstoreImage from "../../images/download-on-appstore.svg";
import GooglePlayBadgeImage from "../../images/google-play-badge-tight.png";
import HelpLink from "../HelpLink";

export type FooterLinkInfo = { slug: string; basePath: string; name: string };
type FooterProps = {
  isInCA: boolean;
  LocalizationButton: React.ReactNode;
  randomCategories: FooterLinkInfo[];
  tutoringCategories: FooterLinkInfo[];
};

export function Footer({
  trackingUniqueId = undefined,
  ...props
}: FooterProps & { trackingUniqueId?: string }) {
  return (
    <ConditionalTrackingPageSection
      name="footer"
      uniqueId={trackingUniqueId || "footer"}
    >
      {setImpressionNode => (
        <FooterContents {...props} ref={setImpressionNode} />
      )}
    </ConditionalTrackingPageSection>
  );
}

const FooterContents = React.forwardRef(FooterContentsToBeForwarded);

function FooterContentsToBeForwarded(
  {
    isInCA,
    LocalizationButton,
    randomCategories,
    tutoringCategories,
  }: FooterProps,
  ref: any
) {
  const { t } = useTranslation("ui-components-website\\Layout\\Footer");
  const LinkColumnsMemo = useMemo(() => {
    return (
      <LinkColumns
        isInCA={isInCA}
        randomCategories={randomCategories}
        tutoringCategories={tutoringCategories}
      />
    );
  }, [isInCA, randomCategories, tutoringCategories]);

  return (
    <Box
      flex
      ref={ref}
      sx={{
        flexDirection: "column",
      }}
    >
      <CurvedTopper animate={false} color="peacock50" />
      <Box
        sx={(theme: Theme) => ({
          backgroundColor: "#F0F3FF",
          paddingY: "2em",

          [theme.breakpoints.up("md")]: {
            paddingY: "8rem",
          },
        })}
      >
        {LinkColumnsMemo}
      </Box>
      <Box
        sx={{
          backgroundColor: "primary.700",
          paddingY: "1em",
          color: "common.white",
          borderTopStyle: "solid",
          borderTopWidth: "4px",
          borderTopColor: "primary.200",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: responsive({
              default: "column",
              medium: "row",
            }),
            justifyContent: "space-between",
            gap: responsive({ default: "medium", medium: undefined }),
            alignItems: responsive({ default: "start", medium: "center" }),
          }}
        >
          <Box>
            {
              // eslint-disable-next-line i18next/no-literal-string
            }
            © {dayjs().format("YYYY")} {t`Outschool`}
          </Box>

          <Box
            flex
            sx={(theme: Theme) => ({
              flexDirection: "column",

              [theme.breakpoints.up("md")]: {
                flexDirection: "row",
                gap: "3em",
              },

              gap: "1em",
            })}
          >
            <SocialLinks />
            {LocalizationButton}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

const SocialLinks = () => {
  const { t } = useTranslation("ui-components-website\\Layout\\Footer");
  const youtubeTitle = t("Join us on Youtube");
  const twitterTitle = t("Follow us on Twitter");
  const pinterestTitle = t("Join us on Pinterest");
  const instagramTitle = t("Follow us on Instagram");
  const facebookTitle = t("Follow us on Facebook");

  return (
    <Box
      flex
      sx={{
        gap: "1em",

        a: {
          color: "common.white",
          fontSize: "0.875em",
        },
      }}
    >
      <ExternalLink url={facebookPageUrl()} trackingName="facebook_link_icon">
        <Icon icon={faFacebook} title={facebookTitle} />
        <span style={visuallyHidden}>{facebookTitle}</span>
      </ExternalLink>
      <ExternalLink url={instagramPageUrl()} trackingName="instagram_link_icon">
        <Icon icon={faInstagram} title={instagramTitle} />
        <span style={visuallyHidden}>{instagramTitle}</span>
      </ExternalLink>
      <ExternalLink url={pinterestPageUrl()} trackingName="pinterest_link_icon">
        <Icon icon={faPinterest} title={pinterestTitle} />
        <span style={visuallyHidden}>{pinterestTitle}</span>
      </ExternalLink>
      <ExternalLink url={twitterPageUrl()} trackingName="twitter_link_icon">
        <Icon icon={faTwitter} title={twitterTitle} />
        <span style={visuallyHidden}>{twitterTitle}</span>
      </ExternalLink>
      <ExternalLink url={youtubePageUrl()} trackingName="youtube_link_icon">
        <Icon icon={faYoutube} title={youtubeTitle} />
        <span style={visuallyHidden}>{youtubeTitle}</span>
      </ExternalLink>
    </Box>
  );
};

function CollapsableLinkColumn({
  expanded = false,
  collapsable = false,
  onToggleExpanded,
  heading,
  children,
}: {
  expanded?: boolean;
  collapsable?: boolean;
  onToggleExpanded(expanded: boolean): void;
  heading: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        flex: 1,
        maxWidth: "250px",
      }}
    >
      <Typography
        variant="h5"
        onClick={() => collapsable && onToggleExpanded(expanded)}
        sx={{
          "&:hover": collapsable && { cursor: "pointer" },
        }}
      >
        {heading}&ensp;
        {collapsable && (
          <Icon
            sx={{
              fontSize: "0.875em",
              fontWeight: "300",
            }}
            icon={expanded ? faChevronUp : faChevronDown}
          />
        )}
      </Typography>
      <Box
        sx={{
          display: !collapsable || expanded ? "grid" : "none",
          gap: "1em",
          marginTop: "1em",

          a: {
            color: "grey.900",

            "&:hover": {
              color: "grey.900",
            },
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

enum LinkColumnNames {
  Learn,
  Teach,
  About,
  Support,
  FinancialAssistance,
  GetTheApp,
  International,
  PopularClasses,
  Tutoring,
  ClassesByAge,
  ClassesByGrade,
}

function LinkColumns({
  isInCA,
  randomCategories,
  tutoringCategories,
}: {
  isInCA: boolean;
  randomCategories: any[];
  tutoringCategories: any[];
}) {
  const Link = useLinkComponent();
  const [expandedColumn, setExpandedColumn] =
    React.useState<LinkColumnNames | null>(null);
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();
  const isMountedAndMobile = isMounted && isMobile;
  const { isUsingAdConversionTracking } = useAdSettings();
  const locale = useLocale();
  const { t } = useTranslation("ui-components-website\\Layout\\Footer");
  const defaultLinks = [
    { slug: "girls-coding", basePath: "popular", name: t`Girls Coding` },
    { slug: "music-and-dance", basePath: "popular", name: t`Music and Dance` },
    {
      slug: "phonics-and-language",
      basePath: "popular",
      name: t`Phonics and Language`,
    },
    { slug: "problem-solving", basePath: "popular", name: t`Problem Solving` },
    {
      slug: "women-in-history",
      basePath: "popular",
      name: t`Women In History`,
    },
    {
      slug: "studying-for-exams",
      basePath: "popular",
      name: t`Studying For Exams`,
    },
    {
      slug: "build-a-computer",
      basePath: "popular",
      name: t`Build A Computer`,
    },
    { slug: "live-streaming", basePath: "popular", name: t`Live Streaming` },
    { slug: "home-economics", basePath: "popular", name: t`Home Economics` },
    {
      slug: "game-development",
      basePath: "popular",
      name: t`Game Development`,
    },
  ];
  const gradeLinks = [
    { slug: "1st-grade", basePath: "", name: t`1st Grade` },
    { slug: "2nd-grade", basePath: "", name: t`2nd Grade` },
    { slug: "3rd-grade", basePath: "", name: t`3rd Grade` },
    { slug: "4th-grade", basePath: "", name: t`4th Grade` },
    { slug: "5th-grade", basePath: "", name: t`5th Grade` },
    { slug: "6th-grade", basePath: "", name: t`6th Grade` },
    { slug: "7th-grade", basePath: "", name: t`7th Grade` },
    { slug: "8th-grade", basePath: "", name: t`8th Grade` },
    { slug: "9th-grade", basePath: "", name: t`9th Grade` },
    { slug: "10th-grade", basePath: "", name: t`10th Grade` },
    { slug: "11th-grade", basePath: "", name: t`11th Grade` },
    { slug: "12th-grade", basePath: "", name: t`12th Grade` },
    { slug: "kindergarten", basePath: "", name: t`Kindergarten` },
    { slug: "preschool", basePath: "", name: t`Preschool` },
    { slug: "elementary-school", basePath: "", name: t`Elementary` },
    { slug: "middle-school", basePath: "", name: t`Middle School` },
    { slug: "high-school", basePath: "", name: t`High School` },
  ];
  const ageLinks = [
    { slug: "for-3-year-olds", basePath: "/age/", name: t`3 Year Olds` },
    { slug: "for-4-year-olds", basePath: "/age/", name: t`4 Year Olds` },
    { slug: "for-5-year-olds", basePath: "/age/", name: t`5 Year Olds` },
    { slug: "for-6-year-olds", basePath: "/age/", name: t`6 Year Olds` },
    { slug: "for-7-year-olds", basePath: "/age/", name: t`7 Year Olds` },
    { slug: "for-8-year-olds", basePath: "/age/", name: t`8 Year Olds` },
    { slug: "for-9-year-olds", basePath: "/age/", name: t`9 Year Olds` },
    { slug: "for-10-year-olds", basePath: "/age/", name: t`10 Year Olds` },
    { slug: "for-11-year-olds", basePath: "/age/", name: t`11 Year Olds` },
    { slug: "for-12-year-olds", basePath: "/age/", name: t`12 Year Olds` },
    { slug: "for-13-year-olds", basePath: "/age/", name: t`13 Year Olds` },
    { slug: "for-14-year-olds", basePath: "/age/", name: t`14 Year Olds` },
    { slug: "for-15-year-olds", basePath: "/age/", name: t`15 Year Olds` },
    { slug: "for-16-year-olds", basePath: "/age/", name: t`16 Year Olds` },
    { slug: "for-17-year-olds", basePath: "/age/", name: t`17 Year Olds` },
    { slug: "for-18-year-olds", basePath: "/age/", name: t`18 Year Olds` },
    { slug: "toddlers", basePath: "", name: t`Toddlers` },
    { slug: "tweens", basePath: "", name: t`Tweens` },
    { slug: "teenagers", basePath: "", name: t`Teenagers` },
  ];

  const popularLinks =
    randomCategories?.length > 0
      ? randomCategories.slice(0, 15).map((category: FooterLinkInfo) => ({
          ...category,
          name: category.name,
        }))
      : defaultLinks;

  const tutoringLinks =
    tutoringCategories?.length > 0
      ? tutoringCategories.slice(0, 15).map((category: FooterLinkInfo) => ({
          ...category,
          name: category.name,
        }))
      : defaultLinks;

  return (
    <>
      <Container
        sx={{
          display: "flex",
          marginBottom: "1.5em",
          flexDirection: responsive({
            default: "column",
            medium: "row",
          }),
          gap: responsive({
            default: "medium",
            medium: "xlarge",
          }),
        }}
      >
        <Box sx={{ flex: 1 }}>
          <CollapsableLinkColumn
            heading={t`About`}
            collapsable={isMountedAndMobile}
            expanded={expandedColumn === LinkColumnNames.About}
            onToggleExpanded={expanded =>
              setExpandedColumn(expanded ? null : LinkColumnNames.About)
            }
          >
            <Link to={aboutPath()} trackingName="about_link">
              {t`Company`}
            </Link>
            <Link
              target="_blank"
              to={pagesPath("deib")}
              trackingName="deib_link"
            >
              {t`DEIB Statement`}
            </Link>
            <Link to={careersPath()} trackingName="careers_link">
              {t`Careers`}
            </Link>
            <Link
              target="_blank"
              to={articlesPath("blog")}
              trackingName="blog_link"
            >
              {t`Blog`}
            </Link>
            <ExternalLink url={pressUrl()} trackingName="press_link">
              {t`Press`}
            </ExternalLink>
          </CollapsableLinkColumn>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CollapsableLinkColumn
            heading={t`Learn`}
            collapsable={isMountedAndMobile}
            expanded={expandedColumn === LinkColumnNames.Learn}
            onToggleExpanded={expanded =>
              setExpandedColumn(expanded ? null : LinkColumnNames.Learn)
            }
          >
            <Link to={onlineClassesPath()} trackingName="listings_link">
              {t`Browse Classes`}
            </Link>
            <ExternalLink
              url={onlineClassesPath("tutoring")}
              trackingName="footer_tutoring"
            >
              {t`Tutoring`}
            </ExternalLink>
            <ExternalLink
              url={newClassesOnOutschoolUrl()}
              trackingName="classes_sitemap_link"
            >
              {t`See All Classes`}
            </ExternalLink>
            <ExternalLink
              url={categorySitemapUrl()}
              trackingName="categories_sitemap_link"
            >
              {t`Explore Categories`}
            </ExternalLink>
            <Link
              to={onlineClassesPath("homeschool")}
              trackingName="homeschool_classes_link"
            >
              {t`Homeschool Classes`}
            </Link>
            <Link
              trackingName="link_to_gift_card_footer"
              trackingUniqueId="link_to_gift_card_footer_from_banner"
              to={giftCardLandingPath()}
            >
              {t`Give a Gift Card`}
            </Link>
          </CollapsableLinkColumn>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CollapsableLinkColumn
            heading={t`Teach`}
            collapsable={isMountedAndMobile}
            expanded={expandedColumn === LinkColumnNames.Teach}
            onToggleExpanded={expanded =>
              setExpandedColumn(expanded ? null : LinkColumnNames.Teach)
            }
          >
            <Link to={teachPath()} trackingName="teach_link">
              {t("Teach on Outschool")}
            </Link>
            <Link to={forSellerOrgPath()} trackingName="seller_org_link">
              {t("Teach as an Organization")}
            </Link>
            <ExternalLink
              url={teachTipsBaseUrl()}
              trackingName="educator_library_link"
            >
              {t("Educator Library")}
            </ExternalLink>
            <ExternalLink
              url={teachTipsEducatorHandbookUrl()}
              trackingName="educator_handbook_link"
            >
              {t("Educator Handbook")}
            </ExternalLink>
            <ExternalLink
              url={teachTipsEducatorInsightsUrl()}
              trackingName="educator_insights_link"
            >
              {t("Educator Insights")}
            </ExternalLink>
            <ExternalLink
              url={teachTipsGettingStartedUrl()}
              trackingName="educator_getting_started_link"
            >
              {t("Getting Started")}
            </ExternalLink>
            <ExternalLink
              url={teachTipsStartTutoringUrl()}
              trackingName="educator_start_tutoring_link"
            >
              {t("Start your Tutoring Business")}
            </ExternalLink>
          </CollapsableLinkColumn>
        </Box>

        <Box sx={{ flex: 1 }}>
          <CollapsableLinkColumn
            heading={t`Support`}
            collapsable={isMountedAndMobile}
            expanded={expandedColumn === LinkColumnNames.Support}
            onToggleExpanded={expanded =>
              setExpandedColumn(expanded ? null : LinkColumnNames.Support)
            }
          >
            <HelpLink
              sx={{
                display: "block",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline !important",
                },
              }}
            >{t`Help`}</HelpLink>
            <Link to={safetyPath()} trackingName="safety_link">
              {t`Safety`}
            </Link>
            <Link
              to={privacyPath(locale)}
              reloadDocument={true}
              trackingName="privacy_link"
            >
              {t`Privacy`}
            </Link>
            <Link
              to={privacyPathCA(locale)}
              reloadDocument={true}
              trackingName="privacy_ca_link"
            >
              {t`CA Privacy`}
            </Link>
            <Link
              to={privacyPathCOPPA(locale)}
              reloadDocument={true}
              trackingName="learner_privacy_link"
            >
              {t`Learner Privacy`}
            </Link>
            <Link
              to={personalizedAdSettingsPath()}
              reloadDocument={true}
              trackingName="personalized_ad_settings_link"
            >
              {t`Your Privacy Choices`}
            </Link>
            <Link
              to={termsPath()}
              reloadDocument={true}
              trackingName="terms_link"
            >
              {t`Terms`}
            </Link>
            {isUsingAdConversionTracking && isInCA && (
              <Link
                to={personalizedAdSettingsPath()}
                trackingName="do_not_sell_my_personal_information_footer"
              >
                {t`Do Not Sell My Personal Information`}
              </Link>
            )}
          </CollapsableLinkColumn>
        </Box>
      </Container>
      <Space y={isMountedAndMobile ? 18 : 40} />

      <Container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: responsive({
            default: "column",
            medium: "row",
          }),
          gap: responsive({
            default: "medium",
            medium: "xlarge",
          }),
        }}
      >
        <Box sx={{ flex: 1 }}>
          <CollapsableLinkColumn
            heading={t`Financial Assistance`}
            collapsable={isMountedAndMobile}
            expanded={expandedColumn === LinkColumnNames.FinancialAssistance}
            onToggleExpanded={expanded =>
              setExpandedColumn(
                expanded ? null : LinkColumnNames.FinancialAssistance
              )
            }
          >
            <ExternalLink
              url={esaFundsFooterPath()}
              trackingName="esa_funds_footer_link"
            >
              {t`Education Savings Account (ESAs)`}
            </ExternalLink>
          </CollapsableLinkColumn>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CollapsableLinkColumn
            heading={t`Outschool International`}
            collapsable={isMountedAndMobile}
            expanded={expandedColumn === LinkColumnNames.International}
            onToggleExpanded={expanded =>
              setExpandedColumn(expanded ? null : LinkColumnNames.International)
            }
          >
            <ExternalLink
              url={pathToUrl(homePath(), I18nLocale.Ko as string)}
              trackingName="korean_link"
            >
              {getLanguageFromLocale(I18nLocale.Ko).localLanguageName}
            </ExternalLink>
            <ExternalLink
              url={pathToUrl(homePath(), I18nLocale.Ja as string)}
              trackingName="japanese_link"
            >
              {getLanguageFromLocale(I18nLocale.Ja).localLanguageName}
            </ExternalLink>
            <ExternalLink
              url={pathToUrl(homePath(), I18nLocale.ZhTw as string)}
              trackingName="mandarin_traditional_link"
            >
              {getLanguageFromLocale(I18nLocale.ZhTw).localLanguageName}
            </ExternalLink>
            <ExternalLink
              url={pathToUrl(homePath(), I18nLocale.Es as string)}
              trackingName="spanish_link"
            >
              {getLanguageFromLocale(I18nLocale.Es).localLanguageName}
            </ExternalLink>
          </CollapsableLinkColumn>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CollapsableLinkColumn
            heading={t`Get The App`}
            collapsable={false}
            expanded={expandedColumn === LinkColumnNames.GetTheApp}
            onToggleExpanded={expanded =>
              setExpandedColumn(expanded ? null : LinkColumnNames.GetTheApp)
            }
          >
            <ExternalLink
              url={downloadOnAppStoreFooterPath()}
              trackingName="download_on_appstore_footer_link"
            >
              <Image
                src={
                  typeof DownloadFromAppstoreImage === "string"
                    ? DownloadFromAppstoreImage
                    : DownloadFromAppstoreImage.src
                }
                width={140}
                height={47}
                alt={t("Download on the App Store")}
              />
            </ExternalLink>
            <ExternalLink
              url={downloadOnGooglePlayFooterPath()}
              trackingName="download_on_appstore_footer_link"
            >
              <Image
                src={GooglePlayBadgeImage}
                width={140}
                height={42}
                alt={t("Get it on Google Play")}
              />
            </ExternalLink>
          </CollapsableLinkColumn>
        </Box>
        <Box sx={{ flex: 1 }}></Box>
      </Container>
      <Space y={isMountedAndMobile ? 18 : 40} />

      <Container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: responsive({
            default: "column",
            medium: "row",
          }),
          gap: responsive({
            default: "medium",
            medium: "xlarge",
          }),
        }}
      >
        <CollapsableLinkColumn
          heading={t`Tutoring`}
          collapsable={isMountedAndMobile}
          expanded={
            isMountedAndMobile
              ? true
              : expandedColumn === LinkColumnNames.Tutoring
          }
          onToggleExpanded={expanded =>
            setExpandedColumn(expanded ? null : LinkColumnNames.Tutoring)
          }
        >
          {tutoringLinks.map((link: FooterLinkInfo, idx: number) => (
            <React.Fragment key={idx}>
              <Link to={onlineClassesPath(link.slug, link.basePath)}>
                {link.name}
              </Link>
            </React.Fragment>
          ))}
        </CollapsableLinkColumn>
        <CollapsableLinkColumn
          heading={t`More to Explore`}
          collapsable={false}
          expanded={true}
          onToggleExpanded={() => null}
        >
          {popularLinks.map((link: FooterLinkInfo, idx: number) => (
            <React.Fragment key={idx}>
              <Link to={onlineClassesPath(link.slug, "popular")}>
                {link.name}
              </Link>
            </React.Fragment>
          ))}
        </CollapsableLinkColumn>
        <CollapsableLinkColumn
          heading={t`Classes by Age`}
          collapsable={false}
          expanded={true}
          onToggleExpanded={() => null}
        >
          {ageLinks.map((link: FooterLinkInfo, idx: number) => (
            <React.Fragment key={idx}>
              <Link to={onlineClassesPath(link.slug, link.basePath)}>
                {link.name}
              </Link>
            </React.Fragment>
          ))}
        </CollapsableLinkColumn>
        <CollapsableLinkColumn
          heading={t`Classes by Grade`}
          collapsable={false}
          expanded={true}
          onToggleExpanded={() => null}
        >
          {gradeLinks.map((link: FooterLinkInfo, idx: number) => (
            <React.Fragment key={idx}>
              <Link to={onlineClassesPath(link.slug, link.basePath)}>
                {link.name}
              </Link>
            </React.Fragment>
          ))}
        </CollapsableLinkColumn>
      </Container>
    </>
  );
}
